import request from '@/utils/request'
// const baseUrl = 'http://192.168.1.71:56666/api'
const baseUrl = 'https://state.renruikeji.cn/api'
const baseWechat = 'https://wechat.renruikeji.cn'
const baseKefu = 'https://kefu.renruikeji.cn'
// 获取企业微信 js sdk 配置信息
export function getJsSdkConfig(data) {
  return request({
    url: '/login/getAgentConfigById',
    method: 'post',
    params: data,
    baseURL: baseWechat
  })
}
// 获取微信 js sdk 配置信息
export function getWxJsSdkConfig(data) {
  return request({
    url: '/wx/getJsapi_ticket',
    method: 'post',
    params: data,
    baseURL: baseKefu
  })
}
export function getUserId(data) {
  return request({
    url: '/login/getTalentUserinfo',
    method: 'get',
    params: data,
    baseURL: baseWechat
  })
}
// 获取用户信息
export function getUserInfo(data) {
  return request({
    url: '/assistant/getUserTags',
    method: 'post',
    params: data,
    baseURL: baseUrl
  })
}
// 获取岗位详情
export function getTaskInfo(data) {
  return request({
    url: '/ir/delive/getJobDetail',
    method: 'get',
    params: data,
    baseURL: baseUrl
  })
}
// 获取岗位数据
export function getJobInfo(data) {
  return request({
    url: '/ir/delive/getPublicJob',
    method: 'get',
    params: data,
    baseURL: baseUrl
  })
}
// 获取搜索条件
export function getSearchInfo(data) {
  return request({
    url: '/ir/delive/getTaskJob',
    method: 'get',
    params: data,
    baseURL: baseUrl
  })
}
// 获取岗位列表
export function getStationList(data) {
  return request({
    url: '/ir/delive/getTaskByParam',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/json' },
    baseURL: baseUrl
  })
}
// 上传简历
export function uploadPersonnel(data) {
  return request({
    url: '/ir/talentPool/updateImg',
    method: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-data' },
    baseURL: baseUrl
  })
}
// 提交简历
export function submitPersonnel(data) {
  return request({
    url: '/ir/delive/deliveResume',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/json' },
    baseURL: baseUrl
  })
}
// 获取招聘管理列表
export function getZpList(data) {
  return request({
    url: '/ir/delive/interviewInfo',
    method: 'get',
    params: data,
    baseURL: baseUrl
  })
}
// 更新简历内容
export function updateZpList(data) {
  return request({
    url: '/ir/delive/update',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/json' },
    baseURL: baseUrl
  })
}
// 获取人员列表
export function getCompList(data) {
  return request({
    url: '/ir/delive/getAllDepartment',
    method: 'post',
    params: data,
    baseURL: baseUrl
  })
}
// 发送验证码
export function sendSms(data) {
  return request({
    url: '/ir/delive/sendSms',
    method: 'post',
    params: data,
    baseURL: baseUrl
  })
}
// 分配面试官
export function changeUser(data) {
  return request({
    url: '/ir/delive/assignInterviewers',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/json' },
    baseURL: baseUrl
  })
}



<template>
  <div class="main">
    <div class="info-box">
      <div class="info-line-box">
        <div class="info-line-left">岗位名称</div>
        <div class="info-line-right">{{userData.job[0].jobName}}</div>
      </div>
      <div class="info-line-box">
        <div class="info-line-left">推荐人</div>
        <div class="info-line-right">{{userData.resume[0].recommendName}}</div>
      </div>
      <div class="info-line-box">
        <div class="info-line-left">姓名</div>
        <div class="info-line-right">{{userData.resume[0].name}}</div>
      </div>
      <div class="info-line-box">
        <div class="info-line-left">年龄</div>
        <div class="info-line-right">{{userData.age}}</div>
      </div>
      <div class="info-line-box">
        <div class="info-line-left">性别</div>
        <div v-if="userData.resume[0].sex === 0" class="info-line-right">女</div>
        <div v-else class="info-line-right">男</div>
      </div>
      <div class="info-line-box">
        <div class="info-line-left">手机号</div>
        <div class="info-line-right">{{userData.resume[0].mobile}}</div>
      </div>
      <div class="info-line-box">
        <div class="info-line-left">面试时间</div>
        <div v-if="userData.interviewRecordList !== null" class="info-line-right">{{userData.interviewRecordList[userData.interviewRecordList.length - 1].interviewDate}}</div>
      </div>
      <div class="info-line-box">
        <div class="info-line-left">简历</div>
        <div class="info-line-right">
          <div v-if="userData.resume[0].resumeUrl" class="info-button"><a style="color: #ffffff" :href="userData.resume[0].resumeUrl">查看</a></div>
          <div v-else>暂无</div>
        </div>
      </div>
      <!--<div v-for="(item,index) in userData.interviewRecordList" v-bind:key="item.id">
        <div v-if="userData.interviewRecordList.length !== index+1" class="info-line-box2">
          <div class="info-line-left">{{item.interviewerName}}</div>
          <div class="info-line-right">{{item.remarks}}</div>
        </div>
      </div>-->
      <div v-for="(item) in userData.mapList" v-bind:key="item.id" class="info-line-box2">
        <div class="info-line-left">{{item.userName}}</div>
        <div class="info-line-right">{{item.content}}</div>
      </div>
      <div v-if="userData.viewStatus === 2"><img class="info-img" src="../../assets/image/ok.png"></div>
      <div v-if="userData.viewStatus === 3"><img class="info-img" src="../../assets/image/no.png"></div>
      <div v-if="isSelf">
        <div v-if="userData.viewStatus !== 2 && userData.viewStatus !== 3" class="message-box">
          <van-cell-group>
            <van-field
                    v-model="message"
                    rows="5"
                    autosize
                    type="textarea"
                    maxlength="500"
                    placeholder="请输入备注"
                    show-word-limit
            />
          </van-cell-group>
        </div>
        <div v-if="userData.viewStatus !== 2 && userData.viewStatus !== 3" class="bottom-button-box">
          <div @click="updateStatus(2)" class="ok-button">通过</div>
          <div @click="updateStatus(3)" class="no-button">不通过</div>
          <div @click="show = true" class="share-button">分派</div>
        </div>
      </div>
    </div>
    <van-popup v-model="show" round position="bottom">
      <van-cascader
              v-model="cascaderValue"
              title="请选择所在地区"
              :options="options"
              @close="show = false"
              @finish="onFinish"
              :field-names="fieldNames"
      />
    </van-popup>
    <van-popup v-model="showDate" round position="bottom">
      <van-datetime-picker
              v-model="currentDate"
              type="datetime"
              title="请选择面试时间"
              @confirm = "toConfirm"
              @cancel = "toCancel"
      />
    </van-popup>
  </div>
</template>

<script>
import { getUserId, getZpList, getCompList, updateZpList, changeUser } from '@/api/service'
import Cookies from 'js-cookie'
import { Toast } from 'vant';
export default {
  name: 'Home',
  components: {
  },
  data() {
    return {
      num:0,
      userId:'',
      query: '',
      message: '',
      interviewerId: '',
      userData: '',
      show: false,
      fieldValue: '',
      showDate: false,
      cascaderValue: '',
      isSelf: true,
      currentDate: new Date(),
      fieldNames: {
        text: 'name',
        value: 'userId',
        children: 'children',
      },
      // 选项列表，children 代表子选项，支持多级嵌套
      options: [],
    }},
  mounted:function(){
    // Cookies.set('userId', 'Wangleiqiang')
    const query = this.$route.query
    let userId=Cookies.get('userId')
    if(!userId){
      this.login()
    }else{
      this.userId=userId
    }
    this.query = query
    if(!query.relationId){
      Toast('参数缺失')
    }
    this.getUserNum()
    this.getUserList()
  },
  methods: {
    // 选择日期确认
    toConfirm(value){
      const postData = {}
      this.showDate = false
      value = this.timeStamp2String(value)
      postData.relationId = this.userData.id
      postData.assignFromId = this.userId
      postData.interviewerId = this.interviewerId
      postData.interviewDate = value
      postData.remark = this.message
      postData.jobName = this.userData.job[0].jobName
      postData.resumeName = this.userData.resume[0].name
      changeUser(postData).then(response => {
        console.log(response)
        this.getUserNum()
        Toast('分配成功')
      })
    },
    // 判断是不是自己面试
    authInfo(){
      if(this.userData.interviewRecordList !== null) {
        if(this.userData.interviewRecordList.length > 0) {
          if(this.userData.interviewRecordList[this.userData.interviewRecordList.length - 1].interviewerId !== this.userId){
            this.isSelf = false
            console.log(123)
          }
        }
      }
    },
    // 格式化时间
    formatDate(date) {
      let d = new Date(date),
              month = '' + (d.getMonth() + 1),
              day = '' + d.getDate(),
              year = d.getFullYear()

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;

      return [year, month, day].join('-');
    },
    timeStamp2String(time){
      let datetime = new Date();
      datetime.setTime(time);
      let year = datetime.getFullYear();
      let month = datetime.getMonth() + 1 < 10 ? "0" + (datetime.getMonth() + 1) : datetime.getMonth() + 1;
      let date = datetime.getDate() < 10 ? "0" + datetime.getDate() : datetime.getDate();
      let hour = datetime.getHours()< 10 ? "0" + datetime.getHours() : datetime.getHours();
      let minute = datetime.getMinutes()< 10 ? "0" + datetime.getMinutes() : datetime.getMinutes();
      let second = datetime.getSeconds()< 10 ? "0" + datetime.getSeconds() : datetime.getSeconds();
      return year + "-" + month + "-" + date+" "+hour+":"+minute+":"+second;
    },
    // 取消
    toCancel(){
      this.showDate = false
    },
    // 全部选项选择完毕后，会触发 finish 事件
    onFinish({ selectedOptions }) {
      this.show = false;
      const interviewerId = selectedOptions[selectedOptions.length-1].userId
      this.interviewerId = interviewerId
      this.showDate = true
    },
    // 用户网页授权登录
    login(){
      let query2=this.$route.query;
      if(query2.code){
        let postData={}
        postData.code=query2.code
        getUserId(postData).then(response => {
          Cookies.set('userId', response.data)
          this.userId=response.data
          this.getUserNum()
        })
      }else{
        let nowUrl = window.location.href;
        let backurl = encodeURIComponent(nowUrl)
        let url = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wwf93ebffd5d31aa7a&redirect_uri=' + backurl + '&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect'
        window.location.href=url
      }
    },
    // 获取数据
    getUserNum() {
      let postData = {}
      postData.relationId = this.query.relationId
      getZpList(postData).then(response => {
        this.userData = response.data
        console.log(this.userData)
        this.authInfo()
      })
    },
    // 获取人员列表
    getUserList() {
      let postData = {}
      postData.type = 2
      getCompList(postData).then(response => {
        console.log(response)
        this.options = response.data.children
      })
    },
    // 通过 不通过
    updateStatus(type) {
      const interviewRecordList = this.userData.interviewRecordList
      interviewRecordList[interviewRecordList.length - 1].remarks = this.message
      let postData = {}
      postData.id = this.query.relationId
      postData.viewStatus = type
      postData.interviewRecordList = interviewRecordList
      /*postData.remark = this.message*/
      updateZpList(postData).then(response => {
        console.log(response)
        this.getUserNum()
        Toast('操作成功')
      })
    },
  }
}
</script>

<style>
  .main {
    background-color: #F8F8F8;
    min-height: 100vh;
    padding: 20px 0;
  }
  .info-box{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: #ffffff;
    margin: 0 5vw 5vw 5vw;
    width: 80vw;
    padding: 5vw;
    border-radius: 4px;
  }
  .info-line-box{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    height: 50px;
    line-height: 50px;
    width: 80vw;
    align-items: center;
  }
  .info-line-box2{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 80vw;
    padding: 10px 0;
  }
  .info-line-left{
    width: 25vw;
    color: rgba(0, 0, 0, 0.6);
    text-align: left;
  }
  .info-line-right{
    width: 55vw;
    flex-wrap: wrap;
  }
  .info-button{
    width: 64px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    color: #ffffff;
    background: #2586FF;
    border-radius: 4px;
  }
  .info-img{
    position: absolute;
    top: 50px;
    right: 30px;
    width: 100px;
    height: 100px;
  }
  .message-box{
    border-radius: 4px;
    border: 1px solid #D9D9D9;
    margin-top: 10px;
  }
  .bottom-button-box{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;
  }
  .ok-button{
    width: 80px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    background-color: #70e508;
    color: #ffffff;
    border-radius: 5px;
  }
  .no-button{
    width: 80px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    background-color: #f6404f;
    color: #ffffff;
    border-radius: 5px;
  }
  .share-button{
    width: 80px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    background-color: #2586FF;
    color: #ffffff;
    border-radius: 5px;
  }
</style>
